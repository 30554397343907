
import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
import { SiteFooterMenuItem } from "~/components/layout/footer/footer-middle/FooterMenu";
import { ChevronIcon } from "~/components/UI/icons";

interface Classes {
  [key: string]: boolean;
}

export default Vue.extend({
  components: {
    ChevronIcon,
  },
  props: {
    borderBottom: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array as PropType<SiteFooterMenuItem[]>,
      required: true,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(["domain"]),
    childListClasses(): Classes {
      return {
        [`overflow-hidden max-h-0 transition-all duration-300 opacity-0`]: true,
        [`!max-h-full pt-3 pb-1 opacity-100`]: this.isActive,
      };
    },
    footerMenuClasses(): Classes {
      return {
        [`py-4 border-t border-white`]: true,
        invisible: this.items && this.items.length === 0,
        [`md:border-b`]: this.borderBottom,
        "border-b": this.isLast,
      };
    },
  },
  methods: {
    stripUrl(url: string) {
      if (url.startsWith(this.domain)) {
        return url.replace(this.domain, "");
      }
      return url;
    },
    isExternalLink(url: string) {
      return (
        !url.startsWith("/") && !url.startsWith(this.$store.getters.domain)
      );
    },
    toggleOpen() {
      this.$emit("toggle");
    },
  },
});
